// GetstartedBtn.js
import React from 'react';

function GetstartedBtn() {
    return (
        <a href="https://app.gocomply.ai/signup" className="rounded-full bg-magenta sm:inline inline-block text-center text-white py-4 px-8 lg:w-auto w-full">Get Started</a> 
    );
}

export default GetstartedBtn;
