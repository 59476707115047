// ASectionTwo.js
import React from 'react';
import AiImg from '../../../assets/images/about-mea.png';

function ASectionTwo() {
  
    return (

        <div className="section lg:p-8 p-0 mx-auto container mb-4">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-8 px-0">
                <div className="px-8 lg:order-last order-first" data-aos="fade-left">
                    <img className="mx-auto" src={AiImg} alt="AI-Powered Ad Compliance" style={{ width: '300px', height: 'auto' }} />
                </div>
                <div className="lg:px-8 px-0 lg:pt-16 pt-4 lg:text-right text-center lg:order-first order-last" data-aos="fade-right">
                    <h3 className="text-white text-4xl mb-4">Meta Alumni Expertise</h3>
                    <p className="text-white text-xl">Our team includes experienced professionals formerly leading performance marketing at Meta. Our proprietary AI solution and in-depth understanding of Meta’s ad policy and auction and delivery systems, yields efficient stable growth for our partners.</p>
                </div>                
            </div>
        </div>
        
    );
  };
  
export default ASectionTwo;