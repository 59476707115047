// Header.js
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../global.scss';
import Logo from '../../components/Logo';
import ScrollToAnchor from '../../components/ScrollToAnchor';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };

    return (
        <header className="header flex p-4 sticky top-0 bg-black z-10">
            <button
            className="block lg:hidden"
            onClick={toggleMenu}
            >
            <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
                ></path>
            </svg>
            </button>

            <div className="logo lg:w-1/6 w-1/2 lg:ms-16"><Logo /></div>
            <div className="flex justify-end lg:w-full pr-16">
                <Router>
                <nav className={`nav lg:pt-0 lg:pb-0 pt-4 pb-4 lg:relative fixed bg-black mt-4 lg:text-end lg:block ${isOpen ? 'block' : 'hidden'}`}>
                    <ul>
                        <li className="nav-item mr-8 lg:pb-0 pb-4">
                            <a href="/">Home</a>
                        </li>
                        <li className="nav-item mr-8 lg:pb-0 pb-4 text-link" onClick={toggleMenu}>
                            <ScrollToAnchor id="aboutsec" offset={100}>About</ScrollToAnchor>
                        </li>
                        {/*<li className="nav-item mr-8 lg:pb-0 pb-4 text-link" onClick={toggleMenu}>
                            <ScrollToAnchor id="hiwsec" offset={100}>How It Works</ScrollToAnchor>
                        </li>*/}
                        <li className="nav-item mr-8 lg:pb-0 pb-4 text-link" onClick={toggleMenu}>
                            <ScrollToAnchor id="footer">Contact</ScrollToAnchor>
                        </li>
                    </ul>
                </nav>
                </Router>
                <div className="mt-4 sign-buttons lg:relative fixed lg:ml-8 ml-4 lg:text-left text-right">
                    <a href="https://core.gocomply.ai/auth/login" className="text-magenta lg:py-0 py-2 mr-4">Sign In</a>
                    <a href="https://app.gocomply.ai/signup" className="rounded-full bg-magenta ml-2 py-2 px-4">Sign Up</a>
                </div>
            </div>
            
        </header>
    );
}

export default Header;
