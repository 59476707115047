//About.jsx
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ASectionOne from './ASectionOne/ASectionOne';
import ASectionTwo from './ASectionTwo/ASectionTwo';
import ASectionThree from './ASectionThree/ASectionThree';
import ASectionFour from './ASectionFour/ASectionFour';
import './About.scss';

function About() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <div id="aboutsec" className="section p-8 mx-auto">
        <h2 className="text-5xl text-white text-center mb-16 text-magenta" data-aos="fade-up">About Us</h2>        
        <ASectionOne />
        <ASectionTwo />
        <ASectionThree />
        <ASectionFour />
    </div>
  );
}

export default About;
