//Home.jsx
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from './HeroSection/HeroSection';
import StatSection from './StatSection/StatSection';
//import HowItWorks from './HowItWorks/HowItWorks';
import About from '../about/About';

function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div>
        <HeroSection />
        <StatSection />
         {/*<HowItWorks />*/}
        <About />
    </div>    
  );
}

export default Home;
