// StatSection.js
import React from 'react';
import './StatSection.scss';
import AdSpend from './AdSpend/AdSpend';
import RejectedAds from './RejectedAds/RejectedAds';
import CpmVariance from './CpmVariance/CpmVariance';

function StatSection() {
  
    return (
        <div className="section p-8 mx-auto mb-16"> {/* Scrollable content */}
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 lg:px-8 px-0">            
                <AdSpend  dataCount="914" />
                <RejectedAds  dataCount="98" />
                <CpmVariance  dataCount="2" />
            </div>
        </div>
    );
  };
  
export default StatSection;