// ASectionFour.js
import React from 'react';
import AiImg from '../../../assets/images/about-tech.jpg';

function ASectionFour() {
  
    return (

        <div className="section p-8 mx-auto container mb-4">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-8 px-0">
                <div className="px-8 lg:order-last order-first" data-aos="fade-left">
                 <img className="mx-auto" src={AiImg} alt="AI-Powered Ad Compliance" style={{ width: '300px', height: 'auto' }} />
                </div>
                <div className="lg:px-8 px-0 lg:pt-16 pt-4 lg:text-right text-center lg:order-first order-last" data-aos="fade-right">
                <h3 className="text-white text-4xl mb-4">Technology-driven Revolution</h3>
                    <p className="text-white text-xl">We are committed to leveraging advanced technology to revolutionize the way businesses grow. By combining our expertise, we provide a platform that sets new standards in stability and compliance.</p>
                </div>                
            </div>
        </div>
        
    );
  };
  
export default ASectionFour;