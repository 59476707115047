// Footer.jsx
import React from 'react';
import '../global.scss';
import Logo from '../../components/Logo';
import FooterLinks from './FooterLinks/FooterLinks';
import FooterContacts from './FooterContacts/FooterContacts';
import FooterEmail from './FooterContacts/FooterEmail';
import FooterBottom from './FooterBottom/FooterBottom';

function Footer() {
    return (
        <footer id="footer">
             <div className="section container p-8 mx-auto">
            <div className="grid lg:grid-cols-4 grid-cols-1 gap-4 lg:px-8 px-0 text-center">        
                <div className="lg:px-8 px-4 mb-4 lg:mx-0 mx-auto">
                    <Logo />
                </div>    
                <FooterLinks />
                <FooterContacts />
                <FooterEmail />
            </div>
            <div>
                <hr className='opacity-10 mb-5 mt-5' />
                <FooterBottom />
            </div>
        </div>
        </footer>
    );
}

export default Footer;