// ASectionThree.js
import React from 'react';
import AiImg from '../../../assets/images/about-reg.png';

function ASectionThree() {
  
    return (

        <div className="section lg:p-8 p-0 mx-auto container mb-4">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-8 px-0">
                <div className="px-8 text-center" data-aos="fade-right">
                 <img className="mx-auto" src={AiImg} alt="AI-Powered Ad Compliance" style={{ width: '300px', height: 'auto' }} />
                </div>
                <div className="lg:px-8 px-0 lg:pt-16 pt-4 lg:text-left text-center" data-aos="fade-left">
                <h3 className="text-white text-4xl mb-4">Regulatory Compliance Mastery</h3>
                    <p className="text-white text-xl">Comply.ai experts bring a wealth of knowledge in regulatory compliance, ensuring that your advertising strategies not only meet industry standards but also adhere to the ever-changing regulatory landscape.</p>
                </div>                
            </div>
        </div>
        
    );
  };
  
export default ASectionThree;