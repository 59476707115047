// AcceptPolicy.jsx
import React from "react";

function AcceptPolicy() {
    return (
        <div className="page">
            <div className="section p-8 pb-16 mx-auto">
                <div className="flex justify-center mt-8">
                    <div className="lg:w-1/2 w-full text-white">
                        <h2 className="text-5xl mb-8">Acceptable Use Policy</h2>
                        <p className="mb-4">
          
          The ComplyAi Service and ComplyAi.tech (collectively, the “ComplyAi Platform”) are services provided by and
          the property of Cross Pacific Global, Inc. and its affiliates. More information about Cross Pacific is
          available at crosspacificglobal.com. With respect to the ComplyAi Platform, Cross Pacific Global, Inc., its
          affiliates, agents, and representatives are doing business as and referred to as “ComplyAi”.
        </p>
        <p className="mb-4">
          
          In connection with use of the ComplyAi Platform you agree not to misuse the ComplyAi Platform or help anyone
          else to do so. This includes not doing or trying to do any of the following:
        </p>
        <ul className="mb-4">
          <li>
            
            Post or transmit abusive messages, defamatory, libelous, false or misleading statements, hate speech, or
            messages that incite or threaten violence;
          </li>
          <li> Transmit spam, chain letters, or unsolicited messages (including email); </li>
          <li>
            
            Impersonate another person, misrepresent your affiliation with another person or entity, engage in fraud, or
            hide or attempt to hide your identity;
          </li>
          <li>
            
            Send altered, deceptive or false source-identifying information, including "spoofing" or "phishing";
          </li>
          <li>
            
            Access any unauthorized or non-public part of the ComplyAi Platform or shared areas you have not been
            invited to;
          </li>
          <li> Interfere with the normal functioning, integrity or operation of the ComplyAi Platform; </li>
          <li> Upload or transmit invalid data, viruses, worms, harmful code, malware, or other software agents; </li>
          <li>
            
            Decipher or decrypt transmissions, circumvent any access, authentication or copy restrictions of, or
            otherwise attempt to compromise the security of the ComplyAi Platform (including another user’s account);
          </li>
          <li>
            
            Attempt to probe, scan or test the vulnerability of any part of the ComplyAi Platform or any ComplyAi system
            or network without proper authorization;
          </li>
          <li>
            
            Attempt to modify, or gain unauthorized use of or access to, another user's account(s), website(s),
            application(s), system(s), equipment or data;
          </li>
          <li>
            
            Access, search, or create accounts for the ComplyAi Platform by any means other than our publicly supported
            interfaces (for example, "scraping" or creating accounts in bulk);
          </li>
          <li> Circumvent account usage or service limits beyond those permitted by your agreement with ComplyAi; </li>
          <li>
            
            Collect or harvest any personally identifiable information, including account names, from any other user’s
            account;
          </li>
          <li> Harass or abuse ComplyAi personnel; </li>
          <li>
            
            Use the ComplyAi Platform in violation of any applicable law or regulation, including privacy laws in
            applicable jurisdictions;
          </li>
          <li> Upload, use or transmit any content, data or materials that violate applicable laws or regulations; </li>
          <li> Violate the privacy of or infringe the rights of others; </li>
          <li>
            
            Use the ComplyAi Platform in violation of any terms of use, policies, rules, or agreements with the Social
            Networks integrated and accessible through the ComplyAi Platform; or
          </li>
          <li> Promote or advertise products or services other than your own without appropriate authorization. </li>
        </ul>
        <p className="mb-4">
          
          Without limiting any other remedies available to it, ComplyAi may in its sole discretion suspend or terminate
          access to the ComplyAi Platform for violations of this Acceptable Use Policy, to prevent harm to other
          parties, or to preserve its security, availability or integrity. Terms not defined in this Acceptable Use
          Policy will have the meaning set forth in the applicable agreement between you and ComplyAi.
        </p>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default AcceptPolicy;