// CpmVariance.js
import React, { useState, useEffect, useRef } from 'react';

const CpmVariance = ({ dataCount }) => {
    const [count, setCount] = useState(0);
    const [isCounting, setIsCounting] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      let observer;
      const currentRef = ref.current; // Store ref.current in a variable
  
      if (currentRef) {
        observer = new IntersectionObserver(
          (entries) => {
            const entry = entries[0];
            if (entry.isIntersecting && !isCounting) {
              startCounting();
            } else if (!entry.isIntersecting && isCounting) {
              resetCounter();
            }
          },
          { threshold: 0.5 }
        );
        observer.observe(currentRef);
      }
  
      return () => {
        if (observer && currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, [isCounting, ref]); // Add ref to the dependency array
  
    useEffect(() => {
      if (isCounting && count < dataCount) {
        const timeout = setTimeout(() => {
          setCount((prevCount) => prevCount + 1);
        }, 50);
        return () => clearTimeout(timeout);
      }
    }, [count, dataCount, isCounting]);
  
    const startCounting = () => {
      setIsCounting(true);
    };
  
    const resetCounter = () => {
      setIsCounting(false);
      setCount(0);
    };
  
    return (
        
            <div className={`lg:px-8 px-4 mt-8 lg:order-first order-last text-center counter ${isCounting ? 'active' : ''}`} ref={ref}>
                <h3 className="text-white text-xl mb-4">CPM VARIANCE</h3>
                <h4 className="text-7xl text-magenta glow">{count}%</h4>
                <small className="text-white text-lg mb-6 block">Average over last 12 months</small>
                <p className="text-white text-xl mb-4">Businesses experience more consistent CPMs given increased stability ComplyAi offers</p>
            </div>
        
    );
  };
  
export default CpmVariance;