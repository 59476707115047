// Partners.js
import React from 'react';
import MetaImg from '../../../assets/images/meta.png';
import './Partners.scss';

function Partners() {
    return (

            <div className="grid lg:grid-cols-5 grid-cols-3 gap-4 lg:px-8 px-4 xl:text-left text-center">
                <div className="mb-6 xl:mx-0 mx-auto">
                    <img src={MetaImg} alt="Meta Business Partner" style={{ width: '80px', height: 'auto' }} />
                </div>
                <div className="mb-6">
                    <i className="fa-brands fa-facebook"></i>
                </div>
                <div className="mb-6">
                    <i className="fa-brands fa-instagram"></i>
                </div>
                <div className="mb-6">
                    <i className="fa-brands fa-facebook-messenger"></i>
                </div>
                <div className="mb-6">
                    <i className="fa-brands fa-whatsapp"></i>
                </div>
            </div>    

    );
}

export default Partners;