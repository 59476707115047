// Logo.js
import React from 'react';
import LogoImg from '../../assets/images/comply-ai-logo.svg';

function Logo() {
    return (
        <a href="/"><img src={LogoImg} alt="ComplyAi" style={{ width: '80px', height: 'auto' }} /></a> 
    );
}

export default Logo;