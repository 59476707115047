// PrivacyPolicy.jsx
import React from "react";

function PrivacyPolicy() {
    return (
        <div className="page">
            <div className="section p-8 pb-16 mx-auto">
                <div className="flex justify-center mt-8">
                    <div className="lg:w-1/2 w-full text-white">
                        <h2 className="text-5xl mb-8">Privacy Policy</h2>
                        <p className="mb-4">
            
            Please review these terms (the “Agreement”) carefully as they form a binding contract between you and
            ComplyAi. Information about acceptance of this Agreement, ComplyAi, the Services, and our respective rights
            and obligations are detailed below.
            </p>
            <p className="mb-4">
            
            By accessing or using the Services you are confirming that you have read, understand and accept this
            Agreement. This Agreement can be updated from time to time as set forth below. You are responsible for
            regularly reviewing the most current version of this Agreement.
            </p>
            <p className="mb-4">
            
            The Services are intended for and their use is limited to business purposes only by persons and organization
            over the age of 18 years. Consumer use is strictly prohibited as is access by minors.
            </p>
            1. Acceptance of Terms of Service as a Binding Agreement
            <b className="m-t-2"> Scope of Agreement </b>
            <p className="mb-4">
            
            Your use of ComplyAi's products, services, content and Website (referred to as the “Service” or the
            “Services”) is governed by the terms set forth in these terms of service and any other document expressly
            referenced herein, excluding any services provided to you under a CPG Business Agreement. You may not use the
            Services if you do not accept these terms.
            </p>
            <b className="m-t-2"> Acceptance of Agreement </b>
            <p className="mb-4">
            
            By (i) clicking to accept or agree to the terms of service/term of use/service agreement where this option is
            made available to you in the user interface for any Service; (ii) making payment for the Services; or (iii)
            actually using the Services, you agree that these terms of service form a binding agreement between you (the
            “Customer”) and Cross Pacific Global, Inc., referred to throughout this Agreement as “ComplyAi”, “we”, or
            “us”.
            </p>
            <p className="mb-4">
            
            This Agreement governs access to and use of the Services and incorporates by reference the policies, pricing,
            and rules made available through the Services such as on ComplyAi Platform including but not limited to the
            Privacy Policy, Acceptable Use Policy, and subscription/plan descriptions. Unless otherwise agreed in writing
            with ComplyAi, your agreement with ComplyAi will always include, at a minimum, the terms and conditions set
            out in this Agreement.
            </p>
            <p className="mb-4">
            
            The ComplyAi Platform is a social media advertising management tool accessible by means of self-service
            through our website ComplyAi.tech (our “Website”) (together the Website and ComplyAi Platform may be referred
            to simply as the “ComplyAi Platform”). The Services are accessed through ComplyAi's proprietary software
            application(s) (referred to as the “Software” below) hosted at the ComplyAi Platform.
            </p>
            <p className="mb-4">
            
            If you enter into a CPG Business Agreement that provides for use of the ComplyAi Platform you agree to the
            licenses, restrictions, and obligations provided herein that are not in conflict with the terms of your CPG
            Business Agreement. This Agreement is secondary to and shall not modify, inhibit, amend, or undermine any CPG
            Business Agreement. A CPG Business Agreement shall control where there are any conflicting provisions with
            this Agreement.
            </p>
            <p className="mb-4">
            
            The Services are provided as “Software as a Service” (“SaaS”) subscription services and we will not deliver
            copies of the Software to you as part of the Services.
            </p>
            <p className="mb-4">
            
            By using the Services, you confirm that: (i) you will only use our Services for business and professional
            reasons; (ii) you are at least 18 years old or otherwise able to enter into a legally binding agreement; (iii)
            you accept and will comply with this Agreement; (iv) if you are using our Services as a representative of an
            organization, you have the power to enter into legally binding agreements for the organization; and (v) you
            are responsible for Authorized Users of your account.
            </p>
            2. Definitions 
            <p className="mb-4">
            
            “Authorized Users” means anyone that uses our Services through your account, such as your employees,
            consultants or contractors.
            </p>
            <p className="mb-4">
            
            “Content” means all information and data (such as text, images, photos, videos, audio and documents) in any
            format that is uploaded to, downloaded from or appears on the Services.
            </p>
            <p className="mb-4">
            
            “Customer Content” is Content that you or your Authorized Users provide to us, or upload to our Services.
            </p>
            <p className="mb-4">
            
            “Mentions” are the Content that a third party creates and that we obtain on your behalf and at your
            instruction from Social Networks or other Third-Party Services that you connect to our Services. For example,
            posts created by your followers that appear on your Facebook page would be considered Mentions.
            </p>
            <p className="mb-4">
            
            “Services” means our websites, such as ComplyAi.tech, the ComplyAi Platform and any services that you access
            or purchase through our Website or ComplyAi Platform, but do not include services provided under a CPG
            Business Agreement or Third-Party Services that you access or use in connection with our services.
            </p>
            <p className="mb-4">
            
            “Third-Party Services” are services that are not provided by ComplyAi but that you may access or use in
            connection with the Services. They include the “Social Networks”, which are the social networking sites
            supported by our Services as well as third-party advertising platforms.
            </p>
            <p className="mb-4">
            
            “CPG Services” means services provided to you by Cross Pacific separate and apart from your self-service use
            of the ComplyAi Platform pursuant to an agreement between you and Cross Pacific.
            </p>
            <p className="mb-4">
            
            “CPG Business Agreement” means an agreement between you and Cross Pacific for the provision of CPG Services
            which may or may not include use of the Services.
            </p>
            <p className="mb-4">
            
            “you”, “your”, “Customer”, and similar terms mean the person or legal entity accessing or using the Services.
            For the avoidance of doubt, if you are accessing and using the Services on behalf of a company (such as your
            employer) or other legal entity, “you”, “your” or “Customer” means the company or other legal entity that you
            are using the Services on behalf of.
            </p>
            3. ComplyAi's Provision of the Services 
            <p className="mb-4">
            
            Subject to the terms of this Agreement, you and your Authorized Users may access and use the paid Services in
            the way set out in the plan you subscribed to. You may not use or access the paid Services in any other way.
            You may not use or access the Services except as provided in this Agreement.
            </p>
            <b> Unavailability of Social Networks </b>
            <p className="mb-4">
            
            The Services interact with Third-Party Services including Social Networks and depend on the availability of
            those Third-Party Services and the features and functionality they make available to us, which we do not
            control and may change without notice. If at any time a Third-Party Service stops making some or all of its
            features or functionality available to us, or available to us on unreasonable terms as determined in our sole
            discretion, we may stop providing access to those features or functionality and we will not be liable to you
            or any third party for any such change.
            </p>
            <b> Changes to the Services </b>
            <p className="mb-4">
            
            ComplyAi is constantly innovating in order to provide the best possible experience for its Customers. The form
            and nature of the Services may change from time to time without prior notice to you as long as such change
            does not result in material degradation of the Services. As part of this continuing innovation, you
            acknowledge and agree that we may permanently or temporarily stop providing the Services or any features
            within the Services in our sole discretion and without prior notice. We will not be liable to you or any third
            party for the modification, price change, suspension or discontinuance of any of Services.
            </p>
            <b> Suspension of Services </b>
            <p className="mb-4">
            
            ComplyAi may, in its full discretion, without any liability to you and without giving you prior notice,
            suspend access to your account for (i) delay with any payment, including delay resulting from your failure to
            provide valid billing details, or (ii) other breach of this Agreement if such breach is material, and that in
            such case you may be prevented from accessing the Services, your account details or any files or other content
            which is contained in your account. If we suspend your access to the Services and subsequently reactivate it,
            for example if the breach has been cured, you still remain obligated to pay for the Services for the entire
            subscription term including the period of suspension as a result of your default; you will not be entitled to
            any compensation, refunds, or extensions. The foregoing shall not in any way prejudice or prevent ComplyAi
            from exercising its right to terminate the Agreement.
            </p>
            <b> Delivery of Services </b>
            <p className="mb-4">
            
            ComplyAi may have subsidiaries and affiliated legal entities in other countries. At times, these companies or
            their employees may serve as contact points with respect to the Services provided to you by ComplyAi. Even
            where ComplyAi's subsidiaries or affiliates are your primary contact points, ComplyAi will remain ultimately
            responsible for the provision of Services.
            </p>
            <b> ComplyAi Rights in the Services </b>
            <p className="mb-4">
            
            We keep all rights and interests in the Services. The Services contain Content owned or licensed by ComplyAi.
            ComplyAi's Content is protected by copyright, trademark, patent, trade secret and other laws, and, as between
            you and ComplyAi, we own and retain all rights in the Services and in any ComplyAi Content.
            </p>
            4. Your Use of the Services 
            <b> Registration </b>
            <p className="mb-4">
            
            To access the Services, you or your Authorized Users may be required to provide identification, contact or
            similar details as part of the registration process for the Services or as part of your continued use of the
            Services. You agree that any registration information you give to ComplyAi will always be accurate, correct
            and up to date.
            </p>
            <p className="mb-4">
            
            You may be required to sign up for an account, select a password and username, and provide us with certain
            information or data, such as your contact information. You shall provide us with accurate, complete, and
            updated registration information about yourself. You may not select as your username a name that you do not
            have the right to use, another person's name with the intent to impersonate that person, or any other username
            that infringes upon the rights of others. You may not transfer your account to anyone else without our prior
            written permission.
            </p>
            <b> Authentication &amp; Account Protection </b>
            <p className="mb-4">
            
            You agree and understand that you are responsible for maintaining the confidentiality of passwords associated
            with any account you or your Authorized Users use to access the Services. Accordingly, you agree that you will
            be solely responsible for all activities that occur under your account. If you become aware of any
            unauthorized use of your password or of your account, you agree to notify us immediately at
            innovators@gocomply.ai.
            </p>
            <p className="mb-4">
            
            ComplyAi uses or may use in the future single-sign on or Third-Party Services account credentials for user
            authentication. If you use such third-party credentials to access the Services and they become deleted or
            non-functional you may no longer be able to access the Services.
            </p>
            <b> Permitted Use of the Services </b>
            <p className="mb-4">
            
            You agree to use the Services only for purposes that are permitted by this Agreement, any applicable law,
            regulation, generally accepted practices, or guidelines in the relevant jurisdictions, including any laws
            regarding the export of data or software to and from the EU, the United States or other relevant countries,
            and any other applicable rules or policies including those of the Third-party Services.
            </p>
            <p className="mb-4">
            
            You and any Authorized Users using your account must comply with our Acceptable Use Policy and all laws. If we
            reasonably believe that you have breached the Acceptable Use Policy or any applicable law, we may, at any time
            and without notice, remove any of your Content and suspend, restrict or terminate your account or your access
            to the Services.
            </p>
            <b> No Reproduction or Duplication </b>
            <p className="mb-4">
            
            Unless you have been specifically permitted to do so in a separate agreement with ComplyAi, you agree that you
            will not reproduce, duplicate, copy, sell, or trade the Services for any purpose.
            </p>
            <b> Resale </b>
            <p className="mb-4">
            
            If permitted by the subscription plan selected, as published on the ComplyAi Platform from time to time, you
            may utilize the Services for the benefit of your own clients or customers at your own risk. This is the only
            manner in which resale of the Services is permitted.
            </p>
            <b> Third Party Fees </b>
            <p className="mb-4">
            
            You acknowledge that additional third-party fees (such as internet service provider fee, social media platform
            fee, fee for boosted or promoted posts, third-party add-on fee or similar) may apply in connection with your
            use of the Services. These third party fees are payable by you directly to the third party and the third party
            receiving such additional fees is responsible for the processing of such fees. You are solely responsible for
            payment of such fees or for maintaining appropriate level of funds where applicable. ComplyAi has no
            responsibility to you or to any third party for payment of any such fees or for unavailability or
            ineffectiveness of the Services due to your failure to do so.
            </p>
            <b> Content Responsibility </b>
            <p className="mb-4">
            
            You are solely responsible for Customer Content. We may, but do not have to, review, filter, block or remove
            Content, including Customer Content. If you use the Services for contests or otherwise ask people to submit
            Content through Services (“Submitted Content”), you acknowledge and agree that: (i) the Services do not help
            you comply with any laws, rules, or regulations that may apply to your collection or use of Submitted Content,
            which is solely your responsibility; and (ii) we will not be liable to you or any third party for the
            Submitted Content, and we make no warranty that any Submitted Content obtained or displayed through the
            Services is accurate or complete.
            </p>
            5. Payment Terms; Prices; Payment Methods. 
            <b> Subscription Plans and Payment Terms </b>
            <p className="mb-4">
            
            Subscription to our plans are for fixed terms, and the associated fees payable for Services are non-refundable
            except as set-forth elsewhere in this Agreement.
            </p>
            <p className="mb-4">
            
            This Agreement includes all subscription rates and payment terms applicable to the Services chosen by you (the
            “Payment Terms”). The Payment Terms, including any related additional terms applicable to your subscription,
            are published on the ComplyAi Platform or are otherwise made available to you either within, or through your
            use of the Services you subscribe to. Prices for Services are subject to change, provided that no price change
            will apply during your then-current subscription term. Prices are stated in US dollars and may be adjusted
            based on the exchange rates applied by our payment processors.
            </p>
            <b> Sales or Value Added Taxes </b>
            <p className="mb-4">
            
            The prices published on the ComplyAi Platform exclude any and all applicable taxes and similar fees imposed on
            provision of the Services, including any sales, use or value added taxes, services tax or withholding tax, and
            you shall be responsible for payment of all such taxes. Depending on the laws of the jurisdictions involved, a
            value added tax or sales tax may be charged based upon the amount of the subscription. In such cases, this tax
            will be charged to you in addition to the subscription amount.
            </p>
            <b> Payment Method </b>
            <p className="mb-4">
            
            You can pay for the Services either by entering your credit card information, or by any other means as
            published, from time to time, on the CxOmmni Platform. If you sign up for the Services online in the user
            interface for the ComplyAi Platform, you agree that you will be charged periodically by ComplyAi pursuant to
            the payment conditions specified at checkout. In addition, you expressly acknowledge and agree that the
            initial price may change if you upgrade or change your subscription plan within the Service. You will always
            be informed about the price modification prior to making the upgrade or changing your subscription.
            </p>
            <p className="mb-4">
            
            We will share your account information with financial institutions and payment processing companies, including
            your submitted payment information, to process your purchase. The terms of your payment will be based on your
            payment method and may be determined by agreements between you and the financial institution, credit card
            issuer or other provider of your chosen payment method. ComplyAi is not responsible for the payment processing
            provided by any third party.
            </p>
            <p className="mb-4">
            
            We do not support all payment methods, currencies or locations for payment and may discontinue or change
            acceptable methods, currencies, and locations from time to time. If the payment method you use is no longer
            valid (such as a credit card that has expired) and you do not edit your payment
            </p>
            <b> Free Trials </b>
            <p className="mb-4">
            
            You must pay all fees for the Services you purchase, except for Services that we expressly offer free of
            charge. If you sign up for a free trial of paid Services, we may require you to provide a valid credit card or
            other payment method. We will charge you automatically on the first day after the free trial is over, unless
            you cancel or downgrade to charge-free Services (if any) before the end of the free-trial period.
            </p>
            <b> Auto-Renewal </b>
            <p className="mb-4">
            
            Services purchased through the ComplyAi Platform will renew automatically at the end of the subscription term
            (which may be monthly or annually, depending on the plan you chose when you purchased the Services), and we
            will automatically bill you on renewal unless you cancel or downgrade the Services. By choosing a recurring
            payment plan, you acknowledge that such Services have an initial and recurring payment feature and you accept
            responsibility for all recurring charges prior to cancellation. You will be notified of the upcoming renewal
            and may notify ComplyAi of your intention not to renew by unsubscribing from the automatic renewal within the
            user interface for the Services. If for any reason you cannot do so through the ComplyAi Platform you should
            notify us by telephone or email immediately. WE MAY SUBMIT PERIODIC CHARGES (E.G., MONTHLY) WITHOUT FURTHER
            AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE PRIOR NOTICE (RECEIPT OF WHICH IS CONFIRMED BY US) THAT YOU HAVE
            TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD. SUCH NOTICE WILL NOT AFFECT CHARGES
            SUBMITTED BEFORE WE REASONABLY COULD ACT.
            </p>
            6. Cancellation of Paid Services 
            <b> Cancellation by Customer </b>
            <p className="mb-4">
            
            You may cancel or downgrade Services purchased through the ComplyAi platform at any time. If you cancel (or
            downgrade) paid Services, you must continue to pay for the rest of your plan term and you are not entitled to
            a refund.
            </p>
            <p className="mb-4">
            
            If you purchase the Services continuously on a monthly “pay as you go” basis you may stop using the Services
            and so terminate your legal agreement with ComplyAi at any time, without the need to notify ComplyAi. This
            shall not apply if you have subscribed to the Services for a fixed subscription term (regardless whether you
            agreed to pay the full fee upfront or in regular instalments) and you acknowledge and agree that during the
            subscription period this Agreement may only be earlier terminated as provided herein.
            </p>
            <b> Earlier Termination for Breach </b>
            <p className="mb-4">
            
            Each of the parties may at any time terminate this Agreement, including any active subscription, if (i) the
            other party has materially breached any provision of this Agreement and failed to cure the breach, where such
            breach is capable of being cured, within a reasonable cure period provided by the other party, or has acted in
            a manner which clearly shows that it does not intend to, or is unable to comply with the provisions of this
            Agreement; (ii) a party is required or permitted to terminate this Agreement by law such as in a bankruptcy
            proceeding or if provision of the Services becomes unlawful. If this Agreement is terminated by ComplyAi due
            to your material breach you will remain responsible for the remaining fees due under your subscription and
            will not be entitled to a refund.
            </p>
            <b> Cancellation by ComplyAi </b>
            <p className="mb-4">
            
            We may refuse to provide the Services to anyone for any reason at any time on a non-discriminatory basis. We
            may terminate this Agreement and cease charging further fees or refund fees paid in advance if we cease to
            offer the Services generally or in a particular country or region. We may also immediately suspend or
            terminate your access to our Services for any reason, at any time, without notifying you in advance. If we
            terminate this Agreement because you violated the Acceptable Use Policy or any other part of this Agreement,
            you will not receive a refund. If we terminate your access to the Services under this section for no reason or
            due to a reduction or discontinuance of the Services, we will refund you for the Services you have not yet
            received.
            </p>
            7. Termination of this Agreement &amp; Survival of Terms 
            <p className="mb-4">
            
            The terms of this Agreement will continue to apply during the term of your original subscription and any
            renewal or extension until terminated by either you or ComplyAi in the manner provided herein. The terms of
            this Agreement, for instance use of the Website apart from paid Services, shall continue throughout your use
            of the Services.
            </p>
            <p className="mb-4">
            
            You may stop using the Services at any time though many of the obligations, representations, warranties,
            licenses, conditions, and restrictions set forth in this Agreement will remain in effect. Our Privacy Policy
            sets forth your rights in your Personal Data during and after your use of the Services and the provisions
            herein with respect to Content extend beyond your termination of use of the Services.
            </p>
            Deletion at Termination 
            <p className="mb-4">
            
            Account termination may result in destruction of any Content associated with your account, though ComplyAi in
            its sole discretion may decide otherwise and exercise all of its rights in the Content provided by this
            Agreement. We will not be liable to you or any third-party as a result of termination of your right to use or
            otherwise access the Services.
            </p>
            <b> Survival </b>
            <p className="mb-4">
            
            The terms of this Agreement generally survive cancellation of any paid Services and remain in effect while you
            continue to access the Services in any manner and govern the use thereof during and after such use. Provisions
            that, by their nature, should survive termination of this Agreement shall survive termination. By way of
            example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any
            limitations on our liability, releases, disclaimers of warranties, any terms regarding ownership or
            intellectual property rights, choices of law and judicial forum, and terms regarding disputes between us,
            including without limitation the arbitration agreement.
            </p>
            8. Proprietary Rights 
            <p className="mb-4">
            
            You acknowledge and agree that ComplyAi (or ComplyAi's licensors) own all legal rights, title and interest in
            and to the Services, including any intellectual property rights which subsist in the Services (whether those
            rights happen to be registered or not, and wherever in the world those rights may exist). You further
            acknowledge that the Services may contain information which is designated confidential by ComplyAi and that
            you shall not disclose such information without ComplyAi's prior written consent.
            </p>
            <b> Limited Grant of License </b>
            <p className="mb-4">
            
            ComplyAi gives you a worldwide, royalty-free, non-assignable and non-exclusive right and license to access and
            use the paid Services through the ComplyAi Platform on a subscription basis for the term and in the scope
            designated by this Agreement, and the non-paid Services (such as non-subscriber access to our Website) to the
            extent they are made available. Any Services unused during your subscription term shall expire at the end of
            the subscription term and cannot be transferred to consecutive terms or be refunded. Further, ComplyAi gives
            you a worldwide, royalty-free, perpetual and non-exclusive right and licence to access and use, reproduce,
            display, distribute, and create derivative works of, Reports that you generate through your use of the
            Services. The license granted in this section is for the sole purpose of enabling you to use and enjoy the
            benefit of the Services as provided by ComplyAi, in the manner permitted by this Agreement. You acknowledge
            that this is a SaaS agreement and that (i) the Software is not sold, and (ii) we will not be delivering copies
            of the Software to you as part of the Services. In addition to the restrictions set forth in the Authorized
            Use Policy, you may not (and you may not permit anyone else to) copy, modify, create a derivative work of,
            reverse engineer, decompile or otherwise attempt to extract the source code of the Software underlying the
            Services or any part thereof. You may not access and/or use the Services and the underlying Software in order
            to build a similar or competitive product.
            </p>
            <b> Use of ComplyAi Marks </b>
            <p className="mb-4">
            
            Unless you have agreed otherwise in writing with ComplyAi, nothing in this Agreement gives you a right to use
            any of ComplyAi's or ComplyAi's affiliates' trade names, trademarks, service marks, logos, domain names and
            any other distinctive brand features. For the avoidance of doubt, if any reports or similar outputs from the
            Services (hereinafter “Reports”) generated by you through the use of the Services include ComplyAi's or
            ComplyAi's affiliates' trade name, trademark, service mark, logo, domain name or other distinctive ComplyAi's
            or ComplyAi's affiliates' brand features, their use in connection with that Report is permitted however you
            agree not to remove, obscure, or alter any proprietary rights notices (including copyright and trademark
            notices) which may be affixed to or contained within the Services.
            </p>
            <b> Customer Rights </b>
            <p className="mb-4">
            
            Other than as set forth in this Agreement, ComplyAi acknowledges and agrees that it obtains no right, title or
            interest from you (or your licensors) in or to any Content that you submit, post, transmit or display on, or
            through, the Services, including any intellectual property rights which subsist in that Content (whether those
            rights happen to be registered or not, and wherever in the world those rights may exist). Unless you have
            agreed otherwise in writing with ComplyAi, you agree that you are responsible for protecting and enforcing
            those rights and that ComplyAi has no obligation to do so on your behalf.
            </p>
            <b> No Third-Party Infringement </b>
            <p className="mb-4">
            
            In connection with the Services, you will not use any trade mark, service mark, trade name, logo of any third
            party company or organization in a way that is likely or intended to cause confusion about the owner or
            authorized user of such marks, names or logos.
            </p>
            9. Content License and Permission Tokens 
            <p className="mb-4">
            
            If you use Services features that allow you to submit, post or display Content on Third-Party Services such as
            Social Networks through the Services, you give ComplyAi a worldwide, royalty-free, and non-exclusive license
            to use, reproduce, adapt, modify, and publish (on your behalf and pursuant to your instructions) such Content
            on Third-Party Services. The grant of this license is necessary for you to be able to use such publishing
            features and the license is limited solely for the purpose of enabling ComplyAi to display, distribute and
            promote your Content to Third-Party Services. You retain copyright and any other intellectual property rights
            to your Content which you submit, post or display on or through the Services. If you provide, as part of your
            use of ComplyAi's Services or as part of the Content which you submit, post or display on or through the
            Services, any photograph or other materials protected by personality or privacy rights, you specifically agree
            that ComplyAi may use such photograph or other materials for the sole purpose of providing the Services.
            ComplyAi, in performing the required technical steps to provide the Services to you, may transmit or
            distribute your Content over various public networks and in various media and make such changes to your
            Content as are necessary to conform and adapt that Content to the technical requirements of connecting
            networks, devices, services or media.
            </p>
            <p className="mb-4">
            
            You acknowledge that provision of ComplyAi's Services may be conditioned upon and subject to you giving
            ComplyAi appropriate access level to your Third-Party Services account or Content by providing respective
            access permissions and you must have appropriate user permissions or roles within the respective Third-Party
            Services. When using the Services, you may be required to grant ComplyAi certain permissions to allow the
            Services access specific information and perform the requested actions.
            </p>
            <p className="mb-4">
            
            You confirm and warrant to ComplyAi that you have all the rights, power and authority necessary to grant the
            above license, access and permissions to ComplyAi.
            </p>
            <p className="mb-4">
            
            If any permission granted by you results in ComplyAi obtaining access to a private layer of data available
            through the respective Third-Party Services, ComplyAi will keep such information confidential at all times,
            except where legally compelled to disclose the relevant information. Such obligation will not, however, apply
            to any information that (i) was publicly known and made generally available in the public domain prior to
            granting the permission to ComplyAi; (ii) becomes publicly known and made generally available after granting
            the permission to ComplyAi other than as a result of a violation of this provision by ComplyAi; (iii) is
            already in the possession of ComplyAi at the time of granting the permission; (iv) is obtained by ComplyAi
            from a third party without a breach of the third party's obligations of confidentiality; or (v) is
            independently developed by ComplyAi without the use of your confidential information.
            </p>
            10. Responsibility For Content &amp; Interactions 
            <p className="mb-4">
            
            Any information or Content publicly posted or privately transmitted through the Services is the sole
            responsibility of the Customer from whom such Content originated, you access all such information and Content
            at your own risk, and we are not liable for any errors or omissions in that information or Content or for any
            damages or loss you might suffer in connection with it. You are responsible for all Content you contribute, in
            any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the
            manner in which you contribute it.
            </p>
            <b> Third-Party Links </b>
            <p className="mb-4">
            
            The Services may contain links or connections to third-party websites or Third-Party Services that are not
            owned or controlled by ComplyAi. ComplyAi has no control over such sites and resources and is not responsible
            for and does not endorse such sites and resources. You further acknowledge and agree that we will not be
            responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
            connection with use of or reliance on any content, events, goods or services available on or through any such
            site or resource. Any dealings you have with third parties found while using the Services are between you and
            the third party. When you access third-party websites or use third-party services, you accept that there are
            risks in doing so, and that we are not responsible for such risks.
            </p>
            <b> Third-Party Content </b>
            <p className="mb-4">
            
            We are not responsible for Content provided by others, including Mentions and Content from Third-Party
            Services such as Content from Social Networks. You and anyone else who accesses our Services may access
            Content that might be unlawful, offensive, harmful, inaccurate or otherwise inappropriate. We will not be
            liable to you or any third party for Content provided by others.
            </p>
            <p className="mb-4">
            
            We may, but do not have to, preview, verify, flag, modify, filter, block or remove Third-Party Services. You
            must comply with all agreements and other legal requirements that apply to Third-Party Services.
            </p>
            <b> Third-Party Services </b>
            <p className="mb-4">
            
            If you access or purchase a Third-Party Service through our Services, you do so at your own risk. Your
            relationship with the Third-Party Service provider is an agreement between you and them. You specifically
            understand that we are not responsible for Third-Party Services and will not be liable to you or any third
            party for any losses or damages resulting from your use of Third-Party Services.
            </p>
            <p className="mb-4">
            
            If you access or enable a Third-Party Service, you grant them permission to access or otherwise process your
            data as required for the operation of the Third-Party Service. We are not responsible for disclosure, use,
            change to or deletion of your data and will not be liable to you or any third party for access to your data by
            a Third-Party Service.
            </p>
            <p className="mb-4">
            
            We may, but do not have to, preview, verify, flag, modify, filter, block or remove Third-Party Services. You
            must comply with all agreements and other legal requirements that apply to Third-Party Services.
            </p>
            11. Privacy Protection and Privacy Policy 
            <b> Privacy Policy </b>
            <p className="mb-4">
            
            Please review our Privacy Policy, which also governs your use of the Services, to understand our practices.
            Our most current Privacy Policy may be found on the ComplyAi Platform.
            </p>
            <b> Children's Online Privacy Protection Act – No Users Under 18 Permitted </b>
            <p className="mb-4">
            
            The Services are not intended for nor authorized for us by children under age 18. If you are a child under 18,
            please do not attempt to register for or otherwise use the Services or send us any Personal Data. The
            Children's Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental
            consent before they knowingly collect personally identifiable information online from children who are under
            thirteen years of age. If we learn we have collected Personal Data from a child under 16, we will delete that
            information as quickly as possible. If you believe that a child under 16 may have provided us Personal Data,
            please contact us at innovators@gocomply.ai or utilizing the contact information for your jurisdiction set
            forth in our Privacy Policy.
            </p>
            <b> Data Protection &amp; the GDPR </b>
            <p className="mb-4">
            
            If your use of the Services includes processing Content that contains “personal data” that is subject to the
            General Data Protection Regulation (EU) 2016/679, you may enter into a Data Processing Addendum (“DPA”) with
            ComplyAi. If you sign a DPA, it will apply to the processing of that personal data and become part of this
            Agreement. Please review our Privacy Policy for more information on how we collect and use data relating to
            the use and performance of the Services. For information regarding and to request a DPA you may email us at
            innovators@gocomply.ai.
            </p>
            <b> Data Security </b>
            <p className="mb-4">
            
            ComplyAi has implemented and will maintain appropriate technical and organizational measures, internal
            controls, and information security routines in accordance with good industry practice and having regard to the
            state of technological development to protect your data against accidental loss, destruction, or alteration;
            unauthorized disclosure or access (including but not limited to taking reasonable steps to ensure the
            reliability of employees having access to your data and providing for limited access rights and access
            controls; authentication; personnel training; regular back up; data recovery and incident management
            procedures; restrictions on storing, printing and disposal of personal data; software protection of devices on
            which personal data are stored; etc.); or unlawful destruction.
            </p>
            <b> Use of Aggregated Data </b>
            <p className="mb-4">
            
            You agree that ComplyAi may use aggregated and anonymized data derived from the data provided by you or
            collected by the program analytics such as user behavior and activities for its own statistics, for auditing,
            for the purposes of product and market research and analytics (which help ComplyAi to optimize and improve its
            Services their usability, the range of Services, and to develop new technologies, products and services), and
            for benchmarks and other analyses. ComplyAi may publish such anonymized data and share them with third parties
            outside of ComplyAi; however, ComplyAi will not directly or indirectly transfer any data received from you to
            (or use such data in connection with) any ad network, ad exchange, data broker, or other advertising or
            monetization related toolset.
            </p>
            12. Copyright, DMCA, and Intellectual Property Policy 
            <b> Intellectual Property Infringement Policy </b>
            <p className="mb-4">
            
            We respect others' intellectual property rights, and we reserve the right to delete or disable Content alleged
            to be infringing, and to terminate the accounts of repeat alleged infringers.
            </p>
            <p className="mb-4">
            
            We respond to notices of alleged copyright infringement under the United States Digital Millennium Copyright
            Act and we respond quickly to the concerns of rights owners, including by disabling Content that is or may be
            infringing upon a protected intellectual property right, and we terminate repeat infringers in appropriate
            circumstances.
            </p>
            <b> Notice of Infringement Procedure </b>
            <p className="mb-4">
            
            If you believe that any of your intellectual property rights have been infringed, including, but not limited
            to, copyright, trademark, and patent claims, you may submit a written claim of infringement, to our Copyright
            Agent at:
            </p>
            <p className="mb-4">
            
            Cross Pacific Global, Inc. <br /> Attention: Copyright Agent <br /> 16192 Coastal HWY <br /> Lewes, DE 19958
            <br /> (310) 426-8542&#x202C;
            <br /> innovators@gocomply.ai
            </p>
            <p className="mb-4">
            
            Written claims concerning intellectual property right infringement must include the following information:
            </p>
            <ul className="mb-4">
            <li>
                
                A physical or electronic signature of the person authorized to act on behalf of the owner of the
                intellectual property right that you claim has been infringed upon;
            </li>
            <li> A description of the intellectual property right that you claim has been infringed upon;</li>
            <li> A description of where the material that you claim is infringing is located on the site;</li>
            <li> Your address, telephone number, and e-mail address;</li>
            <li>
                
                A statement by you that you have a good-faith belief that the disputed use is not authorized by the
                intellectual property right owner, its agent, or the law; and
            </li>
            <li>
                
                A statement by you, made under penalty of perjury, that the above information in your notice is accurate and
                that you are the intellectual property right owner or are authorized to act on the intellectual property
                right owner&apos;s behalf.
            </li>
            </ul>
            <b> Repeat Infringer Policy </b>
            <p className="mb-4">
            
            It is ComplyAi's policy, in appropriate circumstances, to terminate the accounts of Customers who are repeat
            infringers or are repeatedly charged with infringement.
            </p>
            <p className="mb-4">
            
            In determining whether termination is appropriate we may take into account, the number of valid claims of
            infringement against a Customer, and the following considerations, among others:
            </p>
            <ul className="mb-4">
            <li>
                
                whether the infringements were obvious or blatant, as opposed to debatable or unclear, including whether the
                Customer might in good faith have believed that the posted material did not infringe;
            </li>
            <li>
                
                whether infringing matter appears to be the mainstay of the Customer's postings of Content through the
                Services or only a few among myriad lawful postings;
            </li>
            <li>
                
                how many times the Customer has posted blatantly infringing matter, and over how long a period of time;
            </li>
            <li>
                
                how many valid notices of infringement that have resulted in take-downs, even if ComplyAi could not easily
                and fairly determine the merits of the infringement claim;
            </li>
            <li> whether the Customer has filed counter-notices, and the outcome; and</li>
            <li>
                
                whether the Customer has some credible explanation for offering infringing goods or works, and what it may
                be doing to avoid a repetition of the situation.
            </li>
            </ul>
            <p className="mb-4">
            
            Notwithstanding the foregoing, ComplyAi is not obliged to and it is not our policy to conduct an exhaustive or
            costly investigation when Customers are charged with infringement.
            </p>
            13. Messaging, Telephone and Electronic Communications 
            <p className="mb-4">
            
            As part of the Services, you may receive communications through the Services, including messages that we may
            send you (for example, via email or SMS). When signing up for the Services, you may receive a welcome message
            and instructions on how to stop receiving certain messages. By signing up for the Services and providing us
            with your wireless number, you confirm that you want us to send you information that we think may be of
            interest to you, which may include using automated dialing technology to text you at the wireless number you
            provided, and you agree to receive communications from us, and you represent and warrant that each person you
            register for the Services or for whom you provide a wireless phone number has consented to receive
            communications from us. You agree to indemnify and hold us harmless from and against any and all claims,
            liabilities, damages (actual and consequential), losses and expenses (including attorneys' fees) arising from
            or in any way related to your breach of the foregoing.
            </p>
            <b> Consent to Electronic Communications </b>
            <p className="mb-4">
            
            When you use the Services, or send e-mails, text messages, and other communications from your desktop or
            mobile device to ComplyAi you may be communicating with us electronically. You consent to receive
            communications from us but only to the extent permitted by this Agreement and our Privacy Policy,
            electronically, such as e-mails, texts, mobile push notices, or notices and messages on the ComplyAi Platform
            or through the other elements of the Services. You agree that all agreements, notices, disclosures, and other
            communications that we provide to you electronically satisfy any legal requirement that such communications be
            in writing.
            </p>
            <b> Consent to Telephone &amp; Text Communications </b>
            <p className="mb-4">
            
            ComplyAi, including our affiliates and contractors, may contact you using auto-dialed or prerecorded calls and
            text messages, at any telephone number that you have provided us, to provide account notices, account trouble
            shooting, resolve a dispute, collect a debt, poll your opinions through surveys or questionnaires, or as
            otherwise necessary to service your account or enforce this Agreement. You consent to allow ComplyAi to
            contact you using auto-dialed or prerecorded calls and text messages for marketing purposes consistent with
            our Privacy Policy which is incorporated by reference herein.
            </p>
            <b> Consent to Call Recording </b>
            <p className="mb-4">
            
            We may, without further notice or warning and in our discretion, monitor or record telephone conversations you
            or anyone acting on your behalf have with us, or are affiliates or service providers, for quality control and
            training purposes, or for our own protection, and you consent to such recording.
            </p>
            14. Dispute Resolution – Binding Arbitration &amp; Class Action Waiver 
            <p className="mb-4">
            
            PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS. ANY DISPUTE OR CLAIM UNDER THIS AGREEMENT OR
            WITH RESPECT TO THE SERVICES WILL BE SETTLED BY BINDING ARBITRATION AND WILL TAKE PLACE ON AN INDIVIDUAL BASIS
            ONLY; YOU AGREE THAT CLASS, CONSOLIDATED OR REPRESENTATIVE ARBITRATIONS AND CIVIL ACTIONS ARE NOT PERMITTED
            AND ANY RIGHTS TO BRING SUCH ACTIONS ARE WAIVED BY EACH PARTY.
            </p>
            <p className="mb-4">
            
            Both you and ComplyAi acknowledge and agree that for the purposes of any dispute arising out of or relating to
            the subject matter of this Agreement, ComplyAi's officers, directors, advisors, employees and independent
            contractors (“Personnel”) are third-party beneficiaries of this Agreement, and that upon your acceptance of
            this Agreement, Personnel will have the right (and will be deemed to have accepted the right) to enforce this
            Agreement against you as the third-party beneficiary hereof.
            </p>
            <b> Waiver of Class or Consolidated Actions. </b>
            <p className="mb-4">
            
            YOU AND ComplyAi AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT
            AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS, OR REPRESENTATIVE OR PRIVATE ATTORNEY GENERAL ACTION OR
            PROCEEDING. CLAIMS OF MORE THAN ONE USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE
            OF ANY OTHER USER UNLESS YOU AND ComplyAi AGREE OTHERWISE. IN ANY PROCEEDING BETWEEN US, THE ARBITRATOR MAY
            AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
            SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY&apos;S INDIVIDUAL
            CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER USERS.
            </p>
            <p className="mb-4">
            
            If a court decides that applicable law precludes enforcement of any of the foregoing limitations as to a
            particular claim or a particular request for a remedy (such as a request for injunctive relief), then that
            claim or that remedy request (and only that claim or that remedy request) must be severed from the arbitration
            and may be brought in court, subject to you or ComplyAi's right to appeal the court's decision and on the
            remaining terms set forth herein. All other claims remain subject to our agreement to arbitrate.
            </p>
            <b> Arbitration Agreement </b>
            <p className="mb-4">
            
            ANY DISPUTE OR CLAIM UNDER THIS AGREEMENT OR WITH RESPECT TO THE SERVICES WILL BE SETTLED BY BINDING
            ARBITRATION AND WILL TAKE PLACE ON AN INDIVIDUAL BASIS ONLY; YOU AGREE THAT CLASS, CONSOLIDATED OR
            REPRESENTATIVE ARBITRATIONS AND CIVIL ACTIONS ARE NOT PERMITTED AND ANY RIGHTS TO BRING SUCH ACTIONS ARE
            WAIVED BY EACH PARTY.
            </p>
            <p className="mb-4">
            
            The parties understand that, absent this mandatory provision, they would have the right to sue in court and
            have a jury trial. They further understand that, in some instances, the costs of arbitration could exceed the
            costs of litigation and the right to discovery may be more limited in arbitration than in court.
            </p>
            <p className="white-txt"> 1. Applicability of Arbitration Agreement </p>
            <p className="mb-4">
            
            You and ComplyAi agree that any dispute, claim, or controversy arising out of or relating to your use of the
            Services, our relationship, this AGreement or the breach, termination, enforcement, interpretation or validity
            thereof, including the determination of the scope or applicability of this agreement to arbitrate, shall be
            resolved through binding arbitration instead of in courts, and only on an individual basis.
            </p>
            <p className="mb-4">
            
            Binding arbitration is subject to very limited review. Only the arbitrator appointed pursuant to this
            agreement, and not any federal, state or local court will have the authority to resolve any dispute or claim
            relating to this section including, without limitation, regarding the scope, enforceability and arbitrability
            of this Agreement. This arbitration provision will survive termination of this Agreement. This Agreement
            evidences a transaction in interstate commerce and the interpretation and enforcement of this arbitration
            provision is governed by the Federal Arbitration Act, notwithstanding the choice of law set forth below.
            </p>
            <p className="mb-4">
            
            Notwithstanding this agreement to arbitrate, either party may bring enforcement actions, validity
            determinations or claims arising from or relating to theft, piracy or unauthorized use of intellectual
            property in state or federal court or in the U.S. Patent or Trademark Office to protect its Intellectual
            Property Rights.
            </p>
            <p className="white-txt"> 2. Pre-Arbitration Dispute Resolution and Notification </p>
            <p className="mb-4">
            
            The parties shall use their best efforts to settle any dispute, claim, question, or disagreement arising out
            of or relating to the subject matter of this Agreement through good-faith negotiations, which shall be a
            precondition to either party initiating arbitration. We will contact you at the email address you have
            provided to us, you can contact us at the email or mailing addresses contained in this Agreement. If after a
            good faith effort to negotiate one of us feels the dispute has not and cannot be resolved informally, the
            party intending to pursue arbitration agrees to notify the other party via email at least ten days prior to
            initiating the arbitration. Compliance with this provision is a pre-condition to initiating arbitration.
            </p>
            <p className="white-txt"> 3. Arbitration Proceedings </p>
            <p className="mb-4">
            
            The arbitration shall be before a single arbitrator and shall be administered by JAMS pursuant to JAMS&apos;
            Streamlined Arbitration Rules and Procedures. The arbitrator shall be selected from the appropriate list of
            JAMS arbitrators in accordance with such Rules. The arbitrator can grant any remedy that the parties could
            have received in court to resolve the party's individual claim. The arbitrator will issue a reasoned written
            decision sufficient to explain the essential findings and conclusions on which the award is based. All
            decisions by the arbitrator will be final and binding and judgment on the award rendered may be entered in any
            court having jurisdiction. This clause shall not preclude parties from seeking provisional remedies in aid of
            arbitration from a court of appropriate jurisdiction. Information for how to initiate a claim and the Rules
            are available at https://www.jamsadr.com/.
            </p>
            <p className="white-txt"> 4. Location of Arbitration Proceedings </p>
            <p className="mb-4">
            
            The arbitration hearings will take place in Lewes County, Delaware, unless we agree to an alternate location
            in the United States mutually acceptable to both parties.
            </p>
            <p className="white-txt"> 5. Costs of Arbitration </p>
            <p className="mb-4">
            
            The arbitrator shall award to the prevailing party, if any, the costs and attorneys&apos; fees reasonably
            incurred by the prevailing party in connection with the arbitration.
            </p>
            <b> Waiver of Jury Trial </b>
            <p className="mb-4">
            
            YOU AND ComplyAi WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A
            JUDGE OR JURY. You and ComplyAi are instead choosing to have claims and disputes resolved by arbitration.
            Arbitration procedures are typically more limited, more efficient, and less costly than rules applicable in
            court and are subject to very limited review by a court. In any litigation between you and ComplyAi over
            whether to vacate or enforce an arbitration award, YOU AND ComplyAi WAIVE ALL RIGHTS TO A JURY TRIAL and elect
            instead to have the dispute be resolved by a judge, or to the extent our Arbitration Agreement is deemed not
            to apply.
            </p>
            <b> Exclusive Venue </b>
            <p className="mb-4">
            
            In any circumstances where the foregoing Arbitration Agreement permits either you or ComplyAi to litigate any
            dispute arising out of or relating to the subject matter of this Agreement in court, then the Arbitration
            Agreement will not apply to either party. In such case or if the Arbitration Agreement is unenforceable or
            inapplicable, both you and ComplyAi agree that any judicial proceeding will be brought in the state or federal
            courts located in, respectively, Lewes County, Delaware, or the federal district in which that county falls.
            For any actions not subject to arbitration, you and ComplyAi agree to submit to the personal jurisdiction of a
            state or federal court located in Lewes County, Delaware.
            </p>
            <b> Severability &amp; Survival </b>
            <p className="mb-4">
            
            If the prohibition against class actions and other claims brought on behalf of third parties contained above
            is found to be unenforceable, then the Arbitration Agreement section will be null and void.
            </p>
            <p className="mb-4">
            
            This Dispute Resolution section and the Arbitration Agreement contained herein will survive the termination of
            your relationship with ComplyAi.
            </p>
            <b> Choice of Law </b>
            <p className="mb-4">
            
            This Agreement is governed by and will be construed under the Federal Arbitration Act, applicable federal law,
            and the laws of the State of Delaware, without regard to the conflicts of laws provisions thereof.
            </p>
            
            
            15. Disclaimer of Warranties, Limitation of Liability, Release and Indemnification
            
            <p className="mb-4">
            
            As used in this section the “ComplyAi Parties” means ComplyAi and its licensors, suppliers, partners, parent,
            subsidiaries or affiliated entities, and each of their respective officers, directors, members, managers,
            employees, consultants, attorneys, contractors, representatives and agents, and each of their respective
            successors and assigns.
            </p>
            <b> Exclusions </b>
            <p className="mb-4">
            
            Some jurisdictions do not allow the exclusion or disclaimer of certain warranties or the limitation or
            exclusion of certain types of damages, so such disclaimers and exclusions may not apply to you.
            </p>
            <b> Warranty Disclaimer </b>
            <p className="mb-4">
            
            The ComplyAi Parties make no representations or warranties concerning the Services and will not be responsible
            or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed
            through the Services or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising
            out of use of, or in any way related to your use of the Services.
            </p>
            <p className="mb-4">
            
            THE SERVICES AND CONTENT ARE PROVIDED BY THE ComplyAi PARTIES ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY
            KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE.
            </p>
            <b> Limitation of Liability </b>
            <p className="mb-4">
            
            In addition, to the extent permitted by applicable law, the ComplyAi Parties are not liable, and you agree not
            to hold us responsible, for any damages or losses (including, but not limited to, loss of money, goodwill or
            reputation, profits, other intangible losses, or any special, indirect, or consequential damages) resulting
            directly or indirectly from:
            </p>
            <ul className="mb-4">
            <li> the Content you provide (directly or indirectly) using the Services;</li>
            <li> your use of or your inability to use the Services;</li>
            <li> rules, guidelines, suggestions or recommendations provided by ComplyAi;</li>
            <li> delays or disruptions in the Services;</li>
            <li> viruses or other malicious software obtained by accessing or linking to the Services;</li>
            <li> glitches, bugs, errors, or inaccuracies of any kind in the Services;</li>
            <li> damage to your hardware device from the use of the Services;</li>
            <li> the Content, actions, or inactions of other third parties;</li>
            <li> a suspension or other action taken with respect to your account or breach of this Agreement; or</li>
            <li>
                
                your need to modify practices, content, or behavior, or your loss of or inability to do business, as a
                result of changes to this Agreement.
            </li>
            </ul>
            <p className="mb-4">
            
            TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING,
            WITHOUT LIMITATION, TORT, CONTRACT, STRICT LIABILITY, OR OTHERWISE) SHALL ANY OF THE ComplyAi PARTIES BE
            LIABLE TO YOU OR TO ANY THIRD PARTY FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL
            DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF DATA, LOSS OF
            GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, (B) ANY AMOUNT, IN THE
            AGGREGATE, IN EXCESS OF THE GREATER OF (I) ONE-HUNDRED ($100) DOLLARS OR (II) THE AMOUNTS PAID AND/OR PAYABLE
            BY YOU TO ComplyAi IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THE APPLICABLE
            CLAIM, (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, (D) STATEMENTS OR CONDUCT OF
            ANY THIRD PARTY ON THE SERVICES, OR (E) ANY MATTER BEYOND OUR REASONABLE CONTROL.
            </p>
            <b> Indemnification of ComplyAi Parties </b>
            <p className="mb-4">
            
            You agree to indemnify and hold the ComplyAi Parties harmless from and against any and all claims,
            liabilities, damages (actual and consequential), losses and expenses (including attorneys' fees) arising from
            or in any way related to any claims relating to (a) you or your Authorized Users's use of the Services
            (including any actions taken by a third party using your account), and (b) your violation of this Agreement .
            In the event of such a claim, suit, or action (“Claim”), we will attempt to provide notice of the Claim to the
            contact information we have for your account (provided that failure to deliver such notice shall not eliminate
            or reduce your indemnification obligations hereunder).
            </p>
            16. Amendment of this Agreement 
            <p className="mb-4">
            
            ComplyAi may change, amend, or supplement this Agreement, including any terms or documents incorporated by
            reference, from time to time and will alert you to changes by placing a notice on ComplyAi Platform, by
            sending you an email, and/or by some other means. Please note that if you've opted not to receive legal notice
            emails from us, those notices or postings will still govern your use of the Services, and you are still
            responsible for reading and understanding them. If you use the Services after any changes to this Agreement
            have been posted, that means you agree to all of the changes and ComplyAi will treat your use as acceptance of
            the updated Agreement.
            </p>
            17. General Provisions 
            <b> Advertisements </b>
            <p className="mb-4">
            
            Some of the Services may be supported by advertising revenue and may display advertisements and promotions.
            These advertisements may be targeted to the content of information stored on the Services, queries made
            through the Services or other information. The manner, mode and extent of advertising by ComplyAi on the
            Services are subject to change without specific notice to you. In consideration for ComplyAi granting you
            access to and use of the Services, you agree that ComplyAi may place such advertising on the Services.
            </p>
            <b> Confidentiality </b>
            <p className="mb-4">
            
            If we share non-public information about ComplyAi or our Services with you, you must keep it confidential and
            use reasonable security measures to prevent unauthorized disclosure of or access to that information.
            </p>
            <b> Feedback </b>
            <p className="mb-4">
            
            We welcome your suggestions, comments, bug reports, feature requests or other feedback (“Feedback”). We do not
            have to keep Feedback confidential, even if you tell us it is confidential. If you provide Feedback, you grant
            us a non-exclusive, worldwide, perpetual, irrevocable, transferable, sublicensable, royalty-free, fully
            paid-up license to use the Feedback for any purpose.
            </p>
            <b> Export Compliance </b>
            <p className="mb-4">
            
            You will not use or access the Services if you are located in any jurisdiction in which the provision of our
            Services is prohibited under U.S. or other laws (a “Prohibited Jurisdiction”) and you will not provide access
            to the Services to any government, entity or individual located in any Prohibited Jurisdiction. You confirm
            that you are not named on any U.S. government list of persons or entities prohibited from transacting U.S.
            persons; (b) you are not a national of, or a company registered in, any Prohibited Jurisdiction; (c) you will
            not allow Authorized Users to access or use the Services in violation of any U.S. or other export embargoes,
            prohibitions or restrictions; and (d) you will comply with all laws regarding the transmission of data
            exported from the country in which you (or your Authorized Users) are located to the United States.
            </p>
            <b> Notices </b>
            <p className="mb-4">
            
            You agree that ComplyAi may provide you with notices, including those regarding changes to this Agreement by
            email, regular mail, or postings on the ComplyAi Platform. We may also provide notice by placing a banner
            notice on the ComplyAi Platform. We may also contact you or your Authorized Users through your account or
            contact information you provide, such as email or telephone or through your account on one or more Social
            Networks.
            </p>
            <p className="mb-4">
            
            Any notices to ComplyAi must be sent by email to innovators@gocomply.ai with a duplicate copy sent via
            certified mail return receipt requested or overnight service using a nationally recognized courier to Cross
            Pacific Global Inc., Attn: ComplyAi Legal, 16192 Coastal HWY, Lewes, DE 19958.
            </p>
            <b> Force Majeure </b>
            <p className="mb-4">
            
            Except for performance of payment obligations, neither party will be responsible for any failure to perform or
            delay in performing any of its obligations under this Agreement where and to the extent that such failure or
            delay results directly or indirectly from an event beyond such party's reasonable control. We will not be
            liable to you or any third party for any failure to perform our obligations under this Agreement if that
            failure results from any cause beyond our control, such as the elements; fire; flood; severe weather;
            epidemic; pandemic; earthquake; vandalism; accidents; sabotage; power failure; denial of service attacks or
            similar attacks or other acts of hackers; Internet or network failures or interruptions; acts of God and the
            public enemy; acts of war; acts of terrorism; riots; civil or public disturbances; strikes, lockouts or labor
            disruptions; any laws, orders, rules, regulations, acts or restraints of any government or governmental body
            or authority, civil or military, including the orders and judgments of courts; or if another person (including
            Third-Party Services) changes their services or terms or no longer offers their services to you or us on terms
            we believe are reasonable.
            </p>
            <b> No Waiver </b>
            <p className="mb-4">
            
            If you or us do not exercise or enforce any legal right or remedy which is contained in this Agreement, this
            will not be taken to be a formal waiver of such party's rights and those rights or remedies will still be
            available. Any remedies we have under this Agreement are not exclusive of any other remedies we may have under
            this Agreement or otherwise.
            </p>
            <b> Modifications to the Services </b>
            <p className="mb-4">
            
            We are always trying to improve our Services, so they may change over time. We may temporarily or permanently
            modify, suspend or discontinue all or any part of the Services, or we may introduce new features or impose
            limits on certain features or restrict access to parts or all of the Services. We reserve the right to remove
            any Content from the Services in our sole discretion and without notice. You acknowledge and agree that
            ComplyAi will not be liable to you or any third party for taking any of the actions described in this
            paragraph.
            </p>
            <b> Tax Responsibilities </b>
            <p className="mb-4">
            
            You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other
            governmental assessments associated with your activity in connection with the Services including income,
            sales, and use taxes.
            </p>
            <b> No Assignment </b>
            <p className="mb-4">
            
            You may not assign, delegate or transfer this Agreement or your rights or obligations hereunder, or your
            Services account, in any way (by operation of law or otherwise) without our prior written consent. We may
            transfer, assign, or delegate this Agreement and our rights and obligations without consent, except as limited
            by our Privacy Policy.
            </p>
            <b> Severability </b>
            <p className="mb-4">
            
            If any provision of this Agreement is found to be unenforceable or invalid, generally or to a particular
            Customer, that provision will be limited or eliminated, to the minimum extent necessary, so that this
            Agreement shall otherwise remain in full force and effect and enforceable.
            </p>
            <b> No-Third Party Beneficiaries </b>
            <p className="mb-4">
            
            Except as expressly set forth in the Arbitration Agreement, you and ComplyAi agree there are no third-party
            beneficiaries intended under this Agreement.
            </p>
            <b> Entire Agreement </b>
            <p className="mb-4">
            
            This Agreement, including the other documents referred to herein, constitute the entire legal agreement
            between you and ComplyAi and governs your use of the Services, excluding services provides to you under a CPG
            Business Agreement, and completely replaces any prior agreements between you and ComplyAi in relation to the
            Services, other than a CPG Business Agreement. This Agreement is binding on the parties and their permitted
            successors and assigns.
            </p>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default PrivacyPolicy;