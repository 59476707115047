// HeroSection.js
import React from "react";
import Partners from '../Partners/Partners';
import GetstartedBtn from '../../components/GetstartedBtn';
import VideoMP from '../../../assets/videos/gocomply-herobanner.mp4';
import VideoWM from '../../../assets/videos/gocomply-herobanner.webm';
import './HeroSection.scss';


function HeroSection() {
      
    return (
        <div id="herosec" className="section p-8 mx-auto" data-aos="fade" data-aos-duration="3000">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-8 px-0">
                <div className="lg:pr-8 2xl:pl-40 px-0 lg:mt-24 lg:order-first order-last" data-aos="fade-right" data-aos-duration="5000">
                    <h1 className="text-magenta lg:text-5xl text-4xl mb-4">Streamlined Compliance, Infinite Growth</h1>
                    <h6 className="text-white lg:text-3xl text-2xl mb-4">Unrestricted Scaling with <span class="text-magenta">Ai</span></h6>
                    <ul className="text-white list-disc text-xl mb-8">
                        <li>Stabilize and Safeguard Meta Assets</li>
                        <li>98% Less Rejected Ads = Accelerated Scaling</li>
                        <li>Reduce Business Disruption</li>
                    </ul>
                    <div className="mb-12">
                        <GetstartedBtn />
                    </div>
                    <div className="lg:text-start text-center">
                        <Partners />
                    </div>
                </div>
                <div className="lg:order-last order-first lg:mt-8" data-aos="fade-up">
                 <video autoplay="autoplay" loop="loop" playsinline="playsinline" muted="muted">
                    <source src={VideoMP} type="video/mp4" />
                    <source src={VideoWM} type="video/webm" />
                 </video>
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
