// ASectionOne.js
import React from 'react';
import AiImg from '../../../assets/images/about-ai.png';

function ASectionOne() {
  
    return (

        <div className="section lg:p-8 p-0 mx-auto container mb-4">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-8 px-0">
                <div className="px-8 text-center"  data-aos="fade-right">
                 <img className="mx-auto" src={AiImg} alt="AI-Powered Ad Compliance" style={{ width: '300px', height: 'auto' }} />
                </div>
                <div className="lg:px-8 px-0 lg:pt-16 pt-4 lg:text-left text-center" data-aos="fade-left">
                    <h3 className="text-white text-4xl mb-4">AI-Powered Ad Compliance</h3>
                    <p className="text-white text-xl">ComplyAi harnesses the capabilities of artificial intelligence to simplify the complexities of ad policy management. With smart automation, we make sure your advertising efforts comply seamlessly.</p>
                </div>                
            </div>
        </div>
        
    );
  };
  
export default ASectionOne;