//import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';
import Header from './app/global/Header/Header';
import Footer from './app/global/Footer/Footer';
import Home from './app/home/Home';
import About from './app/about/About';
import AcceptPolicy from './app/acceptable-use-policy/AcceptPolicy';
import PrivacyPolicy from './app/privacy-policy/PrivacyPolicy';
import TermsConditions from './app/terms-and-conditions/TermsConditions';

const App = () => {
  return (
    <main>
      <Header />
        <Router>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/acceptable-use-policy" element={<AcceptPolicy />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
            <Route path="/terms-and-conditions" element={<TermsConditions />}></Route>
          </Routes>
        </Router>
      <Footer />
    </main>
    
  );
};

export default App;
